<template>
  <div class="row">
    <div class="col-md-3">
      <left-column />
    </div>
    <div class="col-md-7">
      <div class="card">
        <div class="card-body">
          <h2 class="card-title">Categories</h2>
          <table class="table table-striped">
            <tbody>
              <tr v-for="(category, i) in categories" :key="`category-${i}`">
                <td width="80%">
                  <p :class="`mb-0 ${loading ? 'skeleton skeleton-text' : ''}`">
                    <router-link
                      :to="{
                        name: 'trader.categories.show',
                        params: { id: category.id },
                      }"
                      >{{ category.name }}</router-link
                    >
                  </p>
                </td>
                <td width="20%" v-if="!loading">
                  <p>{{ category.posts }} <small>Posts</small></p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <right-column />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      categories: [{}, {}, {}, {}, {}],
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.loading = true;
      this.$axios.get("/api/v1/trader/categories").then((response) => {
        this.categories = response.data.categories;
        this.loading = false;
      });
    },
  },
};
</script>
